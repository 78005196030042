import React from 'react';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import './Home.css';  // Ensure the path is correct

const AboutUsShort = () => {
  return (
    <div className="about-us-short">
      <h2>About Us</h2>
      <p>
        Founded in 2021, Chem Lab is a leading supplier of high-quality chemical solutions located in Tarapur, near Mumbai. 
        We serve industries across India with superior quality chemicals and unparalleled efficiency.
      </p>
      <Link to="/about">Learn More</Link>  {/* Use Link for client-side navigation */}
    </div>
  );
};

export default AboutUsShort;
