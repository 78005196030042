import React from 'react';
import './ThankYou.css';  // Ensure the path is correct

const ThankYou = () => {
  return (
    <div className="thank-you">
      <h3>Thank You for Visiting!</h3>
      <p>We hope you found what you were looking for.</p>
    </div>
  );
};

export default ThankYou;
