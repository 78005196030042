import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h2>Privacy Policy</h2>
      <div className="policy-content">
        <h3>Introduction</h3>
        <p>Welcome to Chem Lab's Privacy Policy. We are committed to protecting your personal information and ensuring that your experience with us is secure and private.</p>

        <h3>Information We Collect</h3>
        <ul className="policy-list">
          <li><strong>Personal Information:</strong> We may collect personal information such as your name, email address, phone number, and company details when you contact us or place an order.</li>
          <li><strong>Usage Data:</strong> We collect data on how you use our website, including IP addresses, browser types, and referring sites to improve our services.</li>
          <li><strong>Cookies:</strong> Our website uses cookies to enhance your browsing experience. You can manage your cookie preferences through your browser settings.</li>
        </ul>

        <h3>How We Use Your Information</h3>
        <ul className="policy-list">
          <li><strong>Order Processing:</strong> To process and fulfill your orders efficiently.</li>
          <li><strong>Communication:</strong> To send you updates about your orders, promotions, and other relevant information.</li>
          <li><strong>Improvement:</strong> To analyze usage data and improve our website and services.</li>
        </ul>

        <h3>Data Security</h3>
        <p>We take appropriate measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. We use secure servers and encryption to safeguard your data.</p>

        <h3>Third-Party Services</h3>
        <p>We may use third-party services to facilitate our business operations. These third parties are bound by their own privacy policies and may have access to your information as necessary to perform their services.</p>

        <h3>Your Rights</h3>
        <p>You have the right to access, correct, or delete your personal information. If you wish to exercise these rights or have any questions about our privacy practices, please contact us.</p>

        <h3>Changes to This Policy</h3>
        <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date.</p>

        <h3>Contact Us</h3>
        <p>If you have any questions or concerns about our Privacy Policy, please reach out to us at [contact email/phone number].</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
