import React, { useState } from 'react';
import './Contact.css';
import { db, collection, addDoc } from '../firebase';

const Contact = () => {
  const [formData, setFormData] = useState({
    email: '',
    phone: '',
    name: '',
    message: ''
  });
  const [status, setStatus] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) return;

    setIsSubmitting(true);

    try {
      await addDoc(collection(db, 'contactMessages'), formData);
      setSubmitted(true);
      setStatus('Message sent successfully!');
      setFormData({
        email: '',
        phone: '',
        name: '',
        message: ''
      });
    } catch (error) {
      console.error('Error adding document: ', error);
      setStatus('Failed to send message. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      <div className="contact-info">
        <div className="contact-item">
          <h2>Address</h2>
          <p>P112, DOUBLE COLA, NEAR RUCHIRA HOTEL, MIDC TARAPUR, BOISAR 401506</p>
          <p>MAHARASHTRA, IN</p>
        </div>
        <div className="contact-item">
          <h2>Phone</h2>
          <p>+91 84595 58229</p>
        </div>
        <div className="contact-item">
          <h2>Email</h2>
          <p><a href="mailto:chemlabind@gmail.com">chemlabind@gmail.com</a></p>
        </div>
      </div>

      {!submitted ? (
        <form className="contact-form" onSubmit={handleSubmit}>
          <h2>Send Us a Message</h2>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone:</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows="4"
              required
            />
          </div>
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      ) : (
        <div className="submission-confirmation">
          <p>Your response has been submitted. You will get an update within 3-4 working days.</p>
          <button className="product-list-button" onClick={() => setSubmitted(false)}>
            Click to view product list
          </button>
        </div>
      )}
      {status && <p className="status-message">{status}</p>}
    </div>
  );
};

export default Contact;
