import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  return (
    <div className="footer">
      <div className="footer-col">
        <h4>Company</h4>
        <ul>
          <li><Link to="/about">About Us</Link></li>
          <li><Link to="/services">Our Services</Link></li>
          <li><Link to="/privacy-policy">Privacy Policy</Link></li>
        </ul>
      </div>
      <div className="footer-col">
        <h4>Get Help</h4>
        <ul>
          <li><Link to="/faq">FAQ</Link></li>
          <li><Link to="/faq">Shipping</Link></li>
          <li><Link to="/faq">Returns</Link></li>
          <li><Link to="/faq">Order Status</Link></li>
          <li><Link to="/faq">Payment Options</Link></li>
        </ul>
      </div>
      <div className="footer-col">
        <h4>Online Shop</h4>
        <ul>
          <li><Link to="/product">Raw Materials</Link></li>
          <li><Link to="/product">Packages</Link></li>
          <li><Link to="/product">Chemicals</Link></li>
          <li><Link to="/product">Instruments</Link></li>
        </ul>
      </div>
      <div className="footer-col">
        <h4>Follow Us</h4>
        <div className="social-links">
          <a href="https://www.facebook.com/people/CHEM-LAB/61563431376332/" target="_blank" rel="noopener noreferrer"><i className="bx bxl-facebook"></i></a>
          <a href="https://twitter.com/yourprofile" target="_blank" rel="noopener noreferrer"><i className="bx bxl-twitter"></i></a>
          <a href="https://www.instagram.com/chemlabmumbai/" target="_blank" rel="noopener noreferrer"><i className="bx bxl-instagram"></i></a>
          <a href="https://www.linkedin.com/in/yourprofile" target="_blank" rel="noopener noreferrer"><i className="bx bxl-linkedin"></i></a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
