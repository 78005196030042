import React from 'react';
import './FAQ.css';  // Ensure the path is correct

const FAQ = () => {
  const faqs = [
    {
      question: "How do I buy products?",
      answer: "You can buy products by selecting items on our website, adding them to your cart, and proceeding to checkout."
    },
    {
      question: "How do I make a payment?",
      answer: "We accept various payment methods including credit/debit cards and online payment gateways."
    },
    {
      question: "What is your return policy?",
      answer: "You can return products within 30 days of purchase. Please refer to our return policy for more details."
    },
    {
      question: "Is there a delivery charge?",
      answer: "Delivery charges may apply based on your location and the size of your order. You will see the delivery cost during checkout."
    },
    {
      question: "How can I contact customer support?",
      answer: "You can contact us via email at support@yourdomain.com or call us at (123) 456-7890."
    },
    {
      question: "How can I check the list of products?",
      answer: "You can view our product catalog by visiting the 'Products' section on our website."
    },
    {
      question: "Do you offer international shipping?",
      answer: "Yes, we offer international shipping. Shipping charges and delivery times will vary based on the destination."
    },
    {
      question: "How can I track my order?",
      answer: "Once your order is shipped, you will receive a tracking number via email to track your shipment."
    },
    {
      question: "Can I cancel my order?",
      answer: "Orders can be canceled within 24 hours of placing the order. Please contact customer support for assistance."
    },
    {
      question: "Do you offer discounts or promotions?",
      answer: "We periodically offer discounts and promotions. Check our website and subscribe to our newsletter for the latest updates."
    }
  ];

  return (
    <div className="faq-container">
      <h1>Frequently Asked Questions (FAQ)</h1>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <h2 className="faq-question">{faq.question}</h2>
            <p className="faq-answer">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
