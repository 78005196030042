import React from 'react';
import SlidePoster from './SlidePoster';  // Ensure the path is correct
import FeatureArea from './FeatureArea';  // Ensure the path is correct
import ProductCategory from './ProductCategory';  // Ensure the path is correct
import AboutUsShort from './AboutUsShort';  // Shorter version for Home
import ThankYou from './ThankYou';  // Ensure the path is correct
import './Home.css';  // Ensure the path is correct

const Home = () => {
  return (
    <div className="home-container">
      <SlidePoster />
      <FeatureArea />
      <ProductCategory />
      <AboutUsShort />
      <ThankYou />
    </div>
  );
};

export default Home;
