import React from 'react';
import './SlidePoster.css';  // Ensure the path is correct
import poster1 from '../assets/poster1.jpg'; // Adjust the path relative to `SlidePoster.js`

const SlidePoster = () => {
  return (
    <div className="slide-poster">
      <img src={poster1} alt="Slide Poster" className="poster-image" />
      <h2>Welcome to Our Website</h2>
      <p>Explore our amazing features and products.</p>
    </div>
  );
};

export default SlidePoster;
