// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDehllxEJFvYP32_B6z9y6WrC6nArkoSCc",
  authDomain: "chemlab-a9fc3.firebaseapp.com",
  databaseURL: "https://chemlab-a9fc3-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "chemlab-a9fc3",
  storageBucket: "chemlab-a9fc3.appspot.com",
  messagingSenderId: "174629346994",
  appId: "1:174629346994:web:1752bd9538ce86565373ab",
  measurementId: "G-J5MKDC6FB9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db, collection, addDoc }; // Export the required functions and objects
