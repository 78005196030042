// src/components/Features.js

import React from 'react';
import './FeatureArea.css';  // Ensure the path is correct

const features = [
  {
    title: "Product Range and Availability",
    details: [
      "Diverse Product Catalog: A wide range of chemicals, including industrial, laboratory, and specialty chemicals.",
      "Bulk and Custom Orders: Options for bulk purchasing and custom formulations."
    ]
  },
  {
    title: "Quality Assurance",
    details: [
      "Certificates of Analysis: Documentation confirming the quality and purity of the chemicals.",
      "Compliance with Standards: Adherence to international standards such as ISO, ASTM, or REACH."
    ]
  },
  {
    title: "Safety and Handling Information",
    details: [
      "Material Safety Data Sheets (MSDS): Detailed safety information for handling and using chemicals.",
      "Safety Training: Training programs on chemical handling and safety protocols."
    ]
  },
  {
    title: "Technical Support",
    details: [
      "Technical Assistance: Expert advice on chemical applications and troubleshooting.",
      "Application Support: Help with selecting the right chemicals for specific applications."
    ]
  },
  {
    title: "Packaging and Labeling",
    details: [
      "Custom Packaging: Options for custom packaging and labeling to meet customer specifications.",
      "Secure Packaging: Safe and compliant packaging for hazardous materials."
    ]
  },
  {
    title: "Logistics and Delivery",
    details: [
      "Timely Delivery: Efficient and timely delivery services.",
      "Tracking and Updates: Real-time tracking of orders and delivery status."
    ]
  },
  {
    title: "Customer Service",
    details: [
      "Dedicated Account Managers: Personalized service and support from dedicated account managers.",
      "24/7 Support: Round-the-clock customer support for inquiries and issues."
    ]
  },
  {
    title: "Online Ordering and Management",
    details: [
      "User-Friendly Website: An intuitive online platform for ordering and managing purchases.",
      "Order History and Tracking: Access to past orders and real-time tracking."
    ]
  },
  {
    title: "Environmental and Sustainability Initiatives",
    details: [
      "Green Chemistry: Offering environmentally friendly and sustainable chemical solutions.",
      "Waste Disposal Services: Assistance with the safe disposal of chemical waste."
    ]
  },
  {
    title: "Regulatory Compliance",
    details: [
      "Regulatory Assistance: Help with compliance to local and international regulations.",
      "Import/Export Services: Assistance with customs and regulatory requirements for international shipments."
    ]
  },

  {
    title: "Customer Feedback and Improvement",
    details: [
      "Surveys and Feedback: Regular surveys to gather customer feedback and improve services.",
      "Continuous Improvement: Ongoing efforts to enhance product quality and customer satisfaction."
    ]
  }
];

const Features = () => {
  return (
    <div className="features">
      <h1>Our Features</h1>
      <div className="features-list">
        {features.map((feature, index) => (
          <div key={index} className="feature">
            <h2>{feature.title}</h2>
            <ul>
              {feature.details.map((detail, i) => (
                <li key={i}>{detail}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;
