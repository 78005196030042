import React from "react";
import "./About.css";
import labImage from "../assets/lab.png"; // Adjust the path as needed

const About = () => {
  return (
    <div className="about-container">
      <div className="about-content">
        <div className="about-text">
          <h1>Welcome to Chem Lab</h1>
          <p>
            Founded in 2021, Chem Lab is a leading supplier of high-quality
            chemical solutions located in Tarapur, near Mumbai. With our main
            godown and office situated in Tarapur Boisar, we are strategically
            positioned to serve industries across India with unparalleled
            efficiency.
          </p>
          <p>
            At Chem Lab, we specialize in a comprehensive range of products
            including metal salts, electroplating chemicals, metal polishing and
            finishing chemicals, leather finishing chemicals, mining chemicals,
            vitamins, feed grade chemicals, starch, aromatic chemicals,
            fragrances, and cosmetic chemicals. Our extensive product line
            caters to diverse industries such as electroplating, laboratory and
            research, rubber, mining, wood preservation and finishing, leather,
            dye manufacturing, ink manufacturing, metal purification, and paint
            and pigment manufacturing.
          </p>
          <p>
            We are proud to be recognized as one of the fastest-growing chemical
            suppliers in the Mumbai region, delivering superior quality
            chemicals with prompt transportation and meticulous packing. Our
            commitment to excellence ensures that we meet the needs of our
            clients with reliability and precision.
          </p>
          <p>
            Discover the Chem Lab difference and experience unparalleled service
            and product quality across India.
          </p>
        </div>
        <div className="about-image">
          <img src={labImage} alt="Chem Lab" />
        </div>
      </div>
    </div>
  );
};

export default About;
