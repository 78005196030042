import React, { useState } from 'react';
import './Product.css';
import productData from '../data/products.json'; // Ensure this path is correct

const Product = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const totalPages = Math.ceil(productData.length / rowsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0); // Scroll to top when page changes
  };

  const handleRowsChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to first page when rows per page changes
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const currentProducts = productData.slice(startIndex, startIndex + rowsPerPage);

  return (
    <div className="product-container">
      <h1>Our Products</h1>
      <p>Discover our wide range of chemical products.</p>

      <div className="product-table">
        <table>
          <thead>
            <tr>
              <th>Product Name</th>
              <th>CAS Number</th>
            </tr>
          </thead>
          <tbody>
            {currentProducts.length > 0 ? (
              currentProducts.map(product => (
                <tr key={product.casNumber}>
                  <td>{product.name}</td>
                  <td>{product.casNumber}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2">No products found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>

        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={currentPage === index + 1 ? 'active' : ''}
          >
            {index + 1}
          </button>
        ))}

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>

        <select value={rowsPerPage} onChange={handleRowsChange}>
          <option value={25}>25 rows</option>
          <option value={50}>50 rows</option>
          <option value={75}>75 rows</option>
          <option value={100}>100 rows</option>
        </select>
      </div>
    </div>
  );
}

export default Product;
