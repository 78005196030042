// src/components/ProductCategory.js

import React from 'react';
import { Link } from 'react-router-dom';
import './ProductCategory.css';  // Ensure the path is correct

const ProductCategory = () => {
  return (
    <div className="product-category">
      <h3>Product Categories</h3>
      <div className="categories">
        <div className="category-item">
          <Link to="/product#raw-materials">Raw Materials</Link>
        </div>
        <div className="category-item">
          <Link to="/product#packages">Packages</Link>
        </div>
        <div className="category-item">
          <Link to="/product#chemicals">Chemicals</Link>
        </div>
        <div className="category-item">
          <Link to="/product#instruments">Instruments</Link>
        </div>
      </div>
    </div>
  );
};

export default ProductCategory;
