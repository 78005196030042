import React from 'react';
import { FaWhatsapp, FaArrowUp } from 'react-icons/fa';
import './StickyButtons.css'; // Ensure your styles are in this file

const StickyButtons = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="sticky-btn">
      <button className="btn btn-up" onClick={handleScrollToTop}>
        <FaArrowUp />
      </button>
      <a
        href="https://wa.me/8459558229"
        className="btn btn-whatsapp"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp />
      </a>
    </div>
  );
};

export default StickyButtons;
