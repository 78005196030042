import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar'; 
import Footer from './components/Footer'; 
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Contact from './pages/Contact';
import FAQ from './pages/FAQ';
import Product from './pages/Product';
import StickyButtons from './components/StickyButtons';


function App() {
  return (
    <Router>
      <NavBar />
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} /> 
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/product" element={<Product />} />
        </Routes>
      </div>
      <StickyButtons /> {/* Add the sticky buttons here */}
      <Footer />
    </Router>
  );
}

export default App;
