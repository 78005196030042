import React from 'react';
import './Services.css';  // Ensure the path is correct

const Services = () => {
  return (
    <div className="services">
      <h2>Our Services</h2>
      <ul className="service-list">
        <li className="service-item">
          <h3>Chemical Supply</h3>
          <p>Providing a wide range of chemicals for various industrial applications.</p>
        </li>
        <li className="service-item">
          <h3>Custom Orders</h3>
          <p>Sourcing and supplying specialty or hard-to-find chemicals.</p>
        </li>
        <li className="service-item">
          <h3>Bulk Supply</h3>
          <p>Offering chemicals in large quantities and managing logistics for bulk orders.</p>
        </li>
        <li className="service-item">
          <h3>Pre-Order Services</h3>
          <p>Allowing customers to pre-order chemicals to ensure availability and timely delivery.</p>
        </li>
      </ul>
    </div>
  );
};

export default Services;
